'use client';

import type { SxProp, TimelineProps } from '@primer/react';
import { Timeline as Timeline_ } from '@primer/react';
import type React from 'react';
import type { HTMLProps } from 'react';

export const Timeline: React.ForwardRefExoticComponent<Omit<TimelineProps, 'ref'> & React.RefAttributes<HTMLElement>> =
  Timeline_;
export type TimelineItemProps = {
  condensed?: boolean;
  className?: string;
} & SxProp &
  HTMLProps<HTMLDivElement>;
export const TimelineItem: React.ForwardRefExoticComponent<
  Omit<TimelineItemProps, 'ref'> & React.RefAttributes<HTMLElement>
> = Timeline_.Item;
type TimelineBodyProps = {
  /** Class name for custom styling */
  className?: string;
} & SxProp &
  React.HTMLProps<HTMLDivElement>;
export const TimelineBody: React.ForwardRefExoticComponent<
  Omit<TimelineBodyProps, 'ref'> & React.RefAttributes<HTMLElement>
> = Timeline_.Body;
export type TimelineBreakProps = {
  /** Class name for custom styling */
  className?: string;
} & SxProp &
  HTMLProps<HTMLDivElement>;
export const TimelineBreak: React.ForwardRefExoticComponent<
  Omit<TimelineBreakProps, 'ref'> & React.RefAttributes<HTMLElement>
> = Timeline_.Break;

export type TimelineBadgeProps = {
  children?: React.ReactNode;
  className?: string;
} & SxProp &
  React.ComponentPropsWithoutRef<'div'>;
export const TimelineBadge: {
  ({ sx, className, ...props }: TimelineBadgeProps): React.JSX.Element;
  displayName: string;
} = Timeline_.Badge;
