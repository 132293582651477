'use client';

export { useColorSchemeVar } from '@primer/react';

import type { theme as ThemeObject } from '@primer/react';
import * as Primer from '@primer/react';
import type { SimplifyDeep } from 'type-fest/source/simplify-deep';

import type { Values } from '@fidant-io/util';

type ThemeObject = typeof ThemeObject;

export type ColorScheme = keyof ThemeObject['colorSchemes'];

// NOTE: These are nested weird. "{colors: {colors: {…}, shadows: {…}}, shadows: { colors: …},"
type ColorSchemeVars = Required<SimplifyDeep<Required<Values<ThemeObject['colorSchemes']>['colors']>>>;

// "ThemeColorPaths" is defined in terms of an un-exported "ThemeColor" type :-/
// And "Theme" itself is just `{[key: string]: any}`!
export interface ThemeVars extends ThemeObject, ColorSchemeVars {}

export interface Theme extends ThemeObject {}

// Not exported for some reason.
export const defaultColorMode = 'day';
export type ColorMode = 'day' | 'night' | 'light' | 'dark';
export type ColorModeWithAuto = ColorMode | 'auto';

export type ThemeProviderProps = Omit<Primer.ThemeProviderProps, 'theme'> & {
  theme?: Theme;
};

type OrigUseThemeResult = ReturnType<typeof Primer.useTheme>;
type UseThemeResultOverrides = {
  theme?: ThemeVars | undefined;
  colorScheme?: ColorScheme | undefined;
};
export type UseThemeResult = {
  [K in keyof OrigUseThemeResult as K extends 'mode' ? 'resolvedColorMode' : K]: K extends keyof UseThemeResultOverrides
    ? UseThemeResultOverrides[K]
    : OrigUseThemeResult[K];
};

export const useTheme = Primer.useTheme as () => UseThemeResult;

export const ThemeProvider = Primer.ThemeProvider as React.FC<React.PropsWithChildren<ThemeProviderProps>>;

export default ThemeProvider;
