'use client';

import type { BreadcrumbsItemProps } from '@primer/react';
import { Breadcrumbs } from '@primer/react';

export type { BreadcrumbsItemProps, BreadcrumbsProps } from '@primer/react';
export const BreadcrumbsItem: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<BreadcrumbsItemProps> & React.RefAttributes<HTMLAnchorElement>
> = Breadcrumbs.Item;

export { Breadcrumbs };
