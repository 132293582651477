'use client';

import type { SxProp } from '@primer/react';
import { Header as Header_ } from '@primer/react';
import type { StyledComponentBase } from 'styled-components';

// The exported types in @primer/react as of ^36 all resolve to 'any' :-/

export const Header = Header_ as unknown as StyledComponentBase<'header', object, SxProp>;
export const HeaderItem = Header_.Item as StyledComponentBase<
  'div',
  object,
  {
    full?: boolean | undefined;
  } & SxProp
>;

export const HeaderLink = Header_.Link as StyledComponentBase<
  'a',
  object,
  {
    to?: string | Location | undefined;
  } & SxProp
>;
